import { Badge } from '@chakra-ui/react';
import React from 'react';

const SpanishBadge: React.FC = () => {
  return (
    <Badge variant="subtle" colorScheme="orange">
      ¡Hablo español!
    </Badge>
  );
};

export default SpanishBadge;
