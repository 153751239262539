import { Box, HStack, Image, Stack } from '@chakra-ui/react';
import { graphql, PageProps } from 'gatsby';
import React from 'react';

import { ProducerByIdQuery } from '../../graphql-types';
import { SpanishBadge } from '../components/Badges';
import { LeadForm } from '../components/Forms';
import {
  LeadFormIntent,
  LeadFormMode,
} from '../components/Forms/LeadGen/types';
import Layout from '../components/Layout';
import PageIdentifier from '../components/PageIdentifier';
import {
  RepFeatureLinks,
  RsmFeatureLinks,
} from '../components/ProducerFeatureLinks';
import {
  FacebookIcon,
  LinkedInIcon,
  TwitterIcon,
  WLButton,
  WLContainer,
  WLH1,
  WLH3,
  WLLink,
  WLText,
} from '../design-library';
import LiCalcCard from '../design-library/Card/LICalcCard/LICalcCard';
import MissingHeadshot from '../images/missing-headshot.png';
import { formatPhoneNumber } from '../utils/formatting.utils';
import {
  getRepresentativeName,
  getRepresentativeTitle,
  isRecruitingSalesManager,
  isRegionalDirector,
  isSpanishSpeaking,
} from '../utils/representative.utils';

interface ProducerTemplateProps extends PageProps<ProducerByIdQuery> {}

const getFormHeading = (intent: LeadFormIntent, mode: LeadFormMode) => {
  return <WLH3>Let’s start a conversation</WLH3>;
};

const getFormDisclosures = (intent: LeadFormIntent) => {
  if (intent === LeadFormIntent.RECRUIT) {
    return (
      <>
        ¹For those who qualify
        <br />
        After filling out this form, you can expect a call from a Recruiting
        Sales Manager in your area.
        <br />
        <br />
        Representatives are independent contractors and are not employees of
        WoodmenLife. However, certain full-time life insurance sales agents may
        be treated as statutory employees for employment tax purposes (See: IRS
        Publication 15-A).
        <br />
        <br />
        As part of WoodmenLife’s screening process, candidates for a
        Representative contract will be required to complete a criminal
        background check, a credit check (where required for contract), a drug
        screen, and reference checks. Any contract offer will be contingent upon
        successfully passing the above.
      </>
    );
  }
  return (
    <>
      <strong>All products may not be available in all states.</strong>
      This is a solicitation of insurance and an agent may contact you. By
      submitting the above request, I consent to receive phone calls from a
      licensed insurance producer on behalf of Woodmen of the World Life
      Insurance Society (WoodmenLife) and its affiliates at the phone number(s)
      above regarding WoodmenLife products and services. I understand that I can
      contact a Sales Representative directly to make a purchase.
    </>
  );
};

const getSubmitLabel = (intent: LeadFormIntent, mode: LeadFormMode) => {
  if (mode === LeadFormMode.RSM && intent === LeadFormIntent.CONTACT) {
    return 'Request a Quote';
  }
  return 'Submit';
};

const ProducerTemplate: React.FC<ProducerTemplateProps> = ({
  data,
  location,
}) => {
  const person = data.producer!;
  const producerId = data.producer?.producerId!;

  const title = getRepresentativeTitle(person);
  const isRsm = isRecruitingSalesManager(person);
  const isRD = isRegionalDirector(person);
  const fullName = getRepresentativeName(person);

  const image = React.useMemo(
    () =>
      person.image ? (
        <Image
          src={person.image}
          fallbackSrc={MissingHeadshot as string}
          alt={`Image of ${fullName}`}
          maxWidth={250}
        />
      ) : (
        <Image
          src={MissingHeadshot as string}
          alt={`Image of ${fullName}`}
          maxWidth={250}
        />
      ),
    [person, fullName],
  );
  const formattedPhone = React.useMemo(
    () => formatPhoneNumber(person.phone),
    [person.phone],
  );
  const speaksSpanish = isSpanishSpeaking(person);

  return (
    <Layout
      seo={{
        title: `${fullName}, ${title} | Life Insurance Near ${person.city}, ${person.state}`,
        description: `${fullName}, WoodmenLife ${title} | Serving life insurance and retirement products to the ${person.city} area. I'll meet you where you are. Contact me about setting up a video call.`,
        slug: location.pathname || undefined,
        image: person.image || undefined,
      }}
      currentPath={location.pathname}
    >
      <WLContainer width="STANDARD">
        <Stack
          width="full"
          paddingTop="2rem"
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 4, md: 8 }}
          paddingX={{ base: 2, md: 4 }}
          justifyContent="center"
          alignItems={{ base: 'center', md: 'flex-start' }}
        >
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent={{ base: 'center', md: 'flex-start' }}
          >
            <Box position="relative" height="fit-content">
              {image}
              <Box
                as="span"
                backgroundColor="red.700"
                color="white"
                textAlign="center"
                position="absolute"
                bottom={0}
                width="full"
              ></Box>
            </Box>
            <Box
              paddingY={2}
              width="full"
              flexBasis="67%"
              textAlign={{ base: 'center', md: 'unset' }}
            >
              <WLH1>{fullName}</WLH1>
              <WLText as="b">{title}</WLText>
              <WLText color="gray.700">
                {person.city}, {person.state}
              </WLText>
              <HStack spacing={4} justify={{ base: 'center', md: 'start' }}>
                {person.facebookUrl && (
                  <WLLink
                    to={person.facebookUrl || '#'}
                    variant="plain"
                    title={`Facebook page of ${fullName}`}
                  >
                    <FacebookIcon color="blue.800" boxSize="2em" />
                  </WLLink>
                )}
                {person.linkedInUrl && (
                  <WLLink
                    to={person.linkedInUrl || '#'}
                    variant="plain"
                    title={`LinkedIn page of ${fullName}`}
                  >
                    <LinkedInIcon color="blue.800" boxSize="2em" />
                  </WLLink>
                )}
                {person.twitterUrl && (
                  <WLLink
                    to={person.twitterUrl || '#'}
                    variant="plain"
                    title={`Twitter page of ${fullName}`}
                  >
                    <TwitterIcon color="blue.800" boxSize="2em" />
                  </WLLink>
                )}
              </HStack>

              {speaksSpanish && <SpanishBadge />}

              <Box marginTop="1.5rem">
                <WLText>Contact me directly.</WLText>
              </Box>

              {formattedPhone && (
                <Box marginTop="1rem">
                  <WLButton colorScheme="blue" href={`tel:${formattedPhone}`}>
                    {formattedPhone}
                  </WLButton>
                </Box>
              )}
              {person.email && (
                <Box marginTop="1rem">
                  <WLButton colorScheme="blue" href={`mailto:${person.email}`}>
                    Send Me an Email
                  </WLButton>
                </Box>
              )}
              <Box marginTop="1rem" maxW="500px" mb="40px">
                <WLText paddingY={2}>
                  As your WoodmenLife Representative, I will learn what makes
                  you unique, and work with you to tailor solutions based on
                  your financial needs.
                </WLText>
              </Box>
              <LiCalcCard producerId={producerId || null} />
            </Box>
          </Box>

          <Box
            border="solid 1px"
            borderColor="gray.300"
            flexBasis="50%"
            padding="1rem"
            maxW={{ base: '589px', tablet: 'unset' }}
          >
            <LeadForm
              mode={isRsm || isRD ? LeadFormMode.RSM : LeadFormMode.PRODUCER}
              compact={false}
              producerId={person.producerId}
              refPage={isRD ? 'RD' : isRsm ? 'RSM' : 'Sales Rep'}
              heading={getFormHeading}
              disclosures={getFormDisclosures}
              submitLabel={getSubmitLabel}
            />
          </Box>
        </Stack>
      </WLContainer>
      <WLContainer width="FULL_WIDTH" paddingTop={8}>
        {isRsm ? (
          <RsmFeatureLinks />
        ) : (
          <RepFeatureLinks producerId={person.producerId || ''} />
        )}
      </WLContainer>
      <WLContainer width="WIDE" align="start" pt={6} paddingX="19px">
        <PageIdentifier webNumber="Web 111" lastUpdated="3/23" />
      </WLContainer>
    </Layout>
  );
};

export default ProducerTemplate;

export const producerQuery = graphql`
  query ProducerById($id: String!) {
    producer(producerId: { eq: $id }) {
      producerId
      firstName
      alias
      lastName
      city
      state
      zipCode
      phone
      website
      email
      orgCode
      image
      effectiveDate
      orgType
      language
      siteDirectory
      facebookUrl
      linkedInUrl
      twitterUrl
    }
  }
`;
