import { Box, Stack, useMultiStyleConfig } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import { WLButton, WLH2, WLH3 } from '../../../design-library';
interface CardProps {
  producerId?: string | null;
}

export default function LiCalcCard({ producerId }: CardProps) {
  const styles = useMultiStyleConfig('LiCalcCard', {});
  return (
    <Box
      sx={styles.promoCard__LICalc}
      h="auto"
      w={{ base: 'auto', md: '530px', tablet: '570px' }}
      position="relative"
      display="flex"
      flexDirection={{ base: 'column', md: 'row' }}
    >
      <Box
        position={{ base: 'unset', md: 'relative' }}
        flexBasis={{ base: '35%', md: '50%' }}
      >
        <Stack sx={styles.buttonGroup__LICalc} alignItems="center">
          <WLH3>Life Insurance Calculator</WLH3>
          <WLH2 mb="24px">
            How Much
            <br />
            Do You Need?
          </WLH2>
          <Box>
            <WLButton
              href={
                producerId
                  ? `/insurance/life-insurance/how-much-do-i-need/?r=${producerId}`
                  : `/insurance/life-insurance/how-much-do-i-need/`
              }
              sx={styles.button__Primary__LICalc}
            >
              Try Our Calculator
            </WLButton>
          </Box>
        </Stack>
      </Box>
      <Box sx={styles.imgContainer__LICalc}>
        <StaticImage
          src="../../../../static/images/life-insurance-calc-promo.webp"
          alt="Man on his phone using WoodmenLife's Life Insurance Calculator."
          loading="lazy"
        />
      </Box>
    </Box>
  );
}
