import { Image, SimpleGrid } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { RepFeatureLinksQuery } from '../../../graphql-types';
import { WLActionableImage } from '../../design-library';

interface RepFeatureLinksProps {
  producerId: string;
}

const RepFeatureLinks: React.FC<RepFeatureLinksProps> = ({ producerId }) => {
  const data = useStaticQuery<RepFeatureLinksQuery>(graphql`
    query RepFeatureLinks {
      allFile(
        filter: { absolutePath: { glob: "**/src/images/producer/rep/**" } }
        sort: { fields: name }
      ) {
        nodes {
          publicURL
        }
      }
    }
  `);

  const featureImages = data?.allFile?.nodes?.map((node) => node.publicURL);

  if (!featureImages || featureImages.length === 0) {
    return null;
  }

  return (
    <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }}>
      <WLActionableImage
        title="Family Term Life Insurance"
        subText="Protect your family's financial future."
        href={`/landing/family-term-a/?c=${producerId}`}
        image={<Image src={featureImages[0]!} alt="" />}
        color="orange"
      />
      <WLActionableImage
        title="Annuities"
        subText="Meet your retirement goals."
        href="/retirement/"
        image={<Image src={featureImages[1]!} alt="" />}
        color="green"
      />
      <WLActionableImage
        title="Investments"
        subText="Prepare for your great adventure."
        href="/investments/"
        image={<Image src={featureImages[2]!} alt="" />}
        color="blue"
      />
      <WLActionableImage
        title="Want a career with balance?"
        subText="Make great money, and make a difference."
        href="/lifecareerbalance/"
        image={<Image src={featureImages[3]!} alt="" />}
        color="yellow"
      />
    </SimpleGrid>
  );
};

export default RepFeatureLinks;
