import { Badge } from '@chakra-ui/react';
import React from 'react';

const GrowingBadge: React.FC = () => {
  return (
    <Badge variant="subtle" colorScheme="yellow">
      We are growing!
    </Badge>
  );
};

export default GrowingBadge;
