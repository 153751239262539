import { Image, SimpleGrid } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { RsmFeatureLinksQuery } from '../../../graphql-types';
import { WLActionableImage } from '../../design-library';

const RsmFeatureLinks: React.FC = () => {
  const data = useStaticQuery<RsmFeatureLinksQuery>(graphql`
    query RsmFeatureLinks {
      allFile(
        filter: { absolutePath: { glob: "**/src/images/producer/rsm/**" } }
        sort: { fields: name }
      ) {
        nodes {
          publicURL
        }
      }
    }
  `);

  const featureImages = data?.allFile?.nodes?.map((node) => node.publicURL);

  if (!featureImages || featureImages.length === 0) {
    return null;
  }

  return (
    <SimpleGrid columns={{ base: 1, md: 2, lg: 5 }}>
      <WLActionableImage
        title="Be Your Own Boss"
        subText="Get paid for the work you do, not the hours you put in. In addition to your sales earnings, you’ll have opportunities to earn trips, bonuses, recognition, and incentives. Your family is important, and when you set your own schedule, you can be there for them. Get your life back!"
        href="/careers/sales/sales-career/your-career-your-way/"
        image={<Image src={featureImages[0]!} alt="" />}
        color="orange"
      />
      <WLActionableImage
        title="Great Benefits"
        subText="As a WoodmenLife Representative who meets production goals, you are eligible for a full benefits package."
        href="/careers/sales/benefits/"
        image={<Image src={featureImages[1]!} alt="" />}
        color="green"
      />
      <WLActionableImage
        title="Support to Succeed"
        subText="You will receive comprehensive training and support from the start. Managers, mentors and our dedicated Sales Development Team will help you succeed."
        href="/careers/sales/sales-career/training-and-support/"
        image={<Image src={featureImages[2]!} alt="" />}
        color="blue"
      />
      <WLActionableImage
        title="Business Development"
        subText="We offer financial assistance to qualifying new Representatives through programs designed to cushion the transition and help you meet your financial needs."
        href="/careers/sales/sales-career/big-money/"
        image={<Image src={featureImages[3]!} alt="" />}
        color="yellow"
      />
      <WLActionableImage
        title="Make a Difference"
        subText="Feel good about what you do by helping families prepare for their financial future and helping your community thrive through WoodmenLife’s fraternal program."
        href="/extras/"
        image={<Image src={featureImages[4]!} alt="" />}
        color="red"
      />
    </SimpleGrid>
  );
};

export default RsmFeatureLinks;
